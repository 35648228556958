import React from "react"
import Container from "../components/Container"

import Layout from "../components/layout"
import CohubLogo from "../components/Logos/CohubLogo"
import NDWLogo from "../components/Logos/NDWLogo"
import SEO from "../components/seo"

const NDW: React.FC = () => (
  <Layout>
    <SEO title="Nashville Design Week" />
    <Container>
      <NDWLogo size={120} className="mb-8" />
      <div className="text-base font-sans text-gray-500">
        Director of Operations
      </div>
      <div className="font-mono text-gray-500 text-sm">2019 - Present</div>
      <div className="font-sans text-sm mt-6">
        <p>
          Nashville Design Week is a 501(c)(3) non-profit organization that
          hosts a week-long series of events each year in the fall. The events
          bring all facets of Nashville's creative and design communities
          together to showcase their work, meet each other, and invite the
          public to learn about their industries.
        </p>
        <p>
          As Director of Operations, I handle the finances, conduct weekly
          meetings with the other directors, oversee 40+ volunteers (it's an
          entirely volunteer organization), and help put on 20-30 events during
          Design Week.
        </p>
        <p>
          I also manage the website and pitch in with design work where needed.
        </p>
      </div>
      {/* <div className="font-sans text-sm mt-16">
        <h3 className="font-medium text-gray-300 font-serif">
          Cohub Inventory, a Shopify app
        </h3>
        <p>
          We began developing an inventory counting application in 2019 with the
          intent to sell it on Shopify's app marketplace. The app is really two
          apps: one is a webapp that runs inside of Shopify, and the other is a
          companion mobile app for iOS and Android devices.
        </p>
        <p>
          The mobile app allows users to conduct inventory counts with anyone
          else who has a smartphone. Counting inventory normally sucks, so being
          able to divide and conquer and use your phone as a barcode scanner is
          a tremendous time saver.
        </p>
      </div> */}
    </Container>
  </Layout>
)

export default NDW
